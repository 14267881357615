export const GET_EXTERNAL_MATCHES = 'Joga/home/GET_EXTERNAL_MATCHES';
export const GET_POSITIONS_TABLE = 'Joga/home/GET_POSITIONS_TABLE';
export const GET_PLAYERS_RANKING = 'Joga/home/GET_PLAYERS_RANKING';
export const SET_CURRENT_NOTICE = 'Joga/home/SET_CURRENT_NOTICE';
export const GET_NOTICES_SUCCESS = 'Joga/home/GET_NOTICES_SUCCESS';
export const GET_PARAMETERS = 'Joga/home/GET_PARAMETERS';
export const GET_SLIDER_SUCCESS = 'Joga/home/GET_SLIDER_SUCCESS';
export const GET_IDEAL_TEAM = 'Joga/home/GET_IDEAL_TEAM';
export const GET_IDEAL_TEAM_OF_YEAR = 'Joga/home/GET_IDEAL_TEAM_OF_YEAR';
export const GET_IN_SHAPE_PLAYERS = 'Joga/home/GET_IN_SHAPE_PLAYERS';
export const GET_PLAYERS_RECOMMENDED = 'Joga/home/GET_PLAYERS_RECOMMENDED';
export const GET_ARTICLES_AND_CONTENT = 'Joga/home/GET_ARTICLES_AND_CONTENT';
