export const GET_REAL_TEAMS_SUCCESS = 'Joga/draft/GET_REAL_TEAMS_SUCCESS';
export const GET_FANTASY_TEAMS_SUCCESS = 'Joga/draft/GET_FANTASY_TEAMS_SUCCESS';
export const GET_STAGES_SUCCESS = 'joga/draft/GET_STAGES_SUCCESS';
export const GET_CURRENT_STAGES_SUCCESS =
  'joga/draft/GET_CURRENT_STAGES_SUCCESS';
export const GET_STAGES_WEEKS_SUCCESS = 'joga/draft/GET_STAGES_WEEKS_SUCCESS';
export const GET_REAL_PLAYERS_SUCCESS = 'Joga/draft/GET_REAL_PLAYERS_SUCCESS';
export const GET_REAL_PLAYERS_SUCCESS_FOR_PLAYER =
  'Joga/draft/GET_REAL_PLAYERS_SUCCESS_FOR_PLAYER';
export const SET_DRAFT_PLAYERS_SELECTION =
  'Joga/draft/SET_DRAFT_PLAYERS_SELECTION';
export const DELETE_DRAFT_PLAYERS = 'Joga/draft/DELETE_DRAFT_PLAYERS';
export const ADD_DESIRED_PLAYER = 'Joga/draft/ADD_DESIRED_PLAYER';
export const GET_DESIRED_PLAYERS_SUCCESS =
  'Joga/draft/GET_DESIRED_PLAYERS_SUCCESS';
export const DELETE_DESIRED_PLAYER = 'Joga/draft/DELETE_DESIRED_PLAYER';
export const GET_LEAGUE_WAIVERS_SUCCESS =
  'Joga/draft/GET_LEAGUE_WAIVERS_SUCCESS';
export const SET_DRAFT_RESULTS = 'Joga/draft/SET_DRAFT_RESULTS';
export const PROGRESSBAR_UPDATE = 'Joga/draft/PROGRESSBAR_UPDATE';
export const PROGRESSBAR_SHOW = 'Joga/draft/PROGRESSBAR_SHOW';
