import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';
import configureStore from './ducks';
import Routes from './templates';
import './index.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { momenti18n } from './i18n/momenti18n';
import * as serviceWorker from './serviceWorker';

const sampleRate = process.env.REACT_APP_SENTRY_SAMPLE_RATE;

Sentry.init({
  dsn: `https://${process.env.REACT_APP_SENTRY_DSN_PUBLIC_KEY}@${process.env.REACT_APP_SENTRY_DSN_SECRET_KEY}.ingest.us.sentry.io/${process.env.REACT_APP_SENTRY_PROJECT_ID}`,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: parseFloat(sampleRate), //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: parseFloat(sampleRate), // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: parseFloat(sampleRate), // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const theme = createTheme({
  palette: {
    primary: { main: '#01caff' },
    secondary: { main: '#01caff' },
  },
  typography: {
    useNextVariants: true,
  },
});

const { store, persistor } = configureStore();

function App() {
  React.useEffect(() => {
    momenti18n();
  }, []);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MuiThemeProvider theme={theme}>
          <div>
            <ToastContainer />
            <Routes />
          </div>
        </MuiThemeProvider>
      </PersistGate>
    </Provider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

export { store };

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
