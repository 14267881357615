import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import localForage from 'localforage'; // defaults to localStorage for web and AsyncStorage for react-native
import thunk from 'redux-thunk';
import accessStore from './access';
import i18nStore from './i18n';
import draftStore from './draft';
import leagueStore from './league';
import messagesStore from './messages';
import homeStore from './home';

const reducers = combineReducers({
  accessStore,
  i18nStore,
  draftStore,
  leagueStore,
  messagesStore,
  homeStore,
});

const persistConfig = {
  key: 'JOGA',
  storage: localForage,
};
const persistedReducer = persistReducer(persistConfig, reducers);

export default () => {
  const store = createStore(
    persistedReducer,
    undefined,
    compose(applyMiddleware(thunk))
  );
  const persistor = persistStore(store);
  return { store, persistor };
};
