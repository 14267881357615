import API from '../../api';
import * as types from './types';

export const setDraftPlayersSelections = (player, username) => ({
  type: types.SET_DRAFT_PLAYERS_SELECTION,
  payload: player,
  username,
});

export const deleteLeaguePlayers = (leagueId, onSuccess) => (dispatch) => {
  API.delete(`test/leagues/${leagueId}/league-team`).then((response) => {
    dispatch({ type: types.DELETE_DRAFT_PLAYERS });
    if (onSuccess) {
      onSuccess(response);
    }
  });
};

export const getLeagueTeams = (leagueId, onSuccess) => () => {
  API.get(`/leagues/${leagueId}/league-teams`).then((response) => {
    // if (Array.isArray(response.data)) {
    //   dispatch({ type: types.GET_MY_TEAMS_SUCCESS, payload: response.data });
    // }
    if (onSuccess) {
      onSuccess(response);
    }
  });
};

export const getRealTeams = (onSuccess) => (dispatch) => {
  API.get(`/api-external/api-teams?sort=name`).then((response) => {
    if (Array.isArray(response.data)) {
      dispatch({
        type: types.GET_REAL_TEAMS_SUCCESS,
        payload: response.data,
      });
    }
    if (onSuccess) {
      onSuccess(response);
    }
  });
};

export const getFantasyTeams = (league, onSuccess) => (dispatch) => {
  API.get(`/leagues/${league}/league-teams?sort=name`).then((response) => {
    if (Array.isArray(response.data)) {
      dispatch({
        type: types.GET_FANTASY_TEAMS_SUCCESS,
        payload: response.data,
      });
    }
    if (onSuccess) {
      onSuccess(response);
    }
  });
};

export const getStages = (onSuccess) => (dispatch) => {
  API.get(`/api-external/api-stages`).then((response) => {
    if (Array.isArray(response.data)) {
      const currentStage = response.data.find(
        (stage) => stage?.current === true
      );
      if (currentStage) {
        dispatch({
          type: types.GET_CURRENT_STAGES_SUCCESS,
          payload: currentStage,
        });
      }
      dispatch({
        type: types.GET_STAGES_SUCCESS,
        payload: response.data,
      });
    }
    if (onSuccess) {
      onSuccess(response);
    }
  });
};

export const getStagesWeeks = (stage_id, onSuccess) => (dispatch) => {
  API.get(`api-external/api-stages/weeks?stage=${stage_id}`).then(
    (response) => {
      if (Array.isArray(response.data)) {
        dispatch({
          type: types.GET_STAGES_WEEKS_SUCCESS,
          payload: response.data,
        });
      }
      if (onSuccess) {
        onSuccess(response);
      }
    }
  );
};

export const getRealPlayers =
  (query = '', onSuccess) =>
  async (dispatch) => {
    dispatch({
      type: types.PROGRESSBAR_SHOW,
      payload: true,
    });
    const timePerPlayer = 0.011;
    const timeFix = 20;
    const respCount = await API.get('/api-external/api-players/count');
    const totalTime = respCount.data.count * timePerPlayer * timeFix;
    for (let cycle = 0; cycle < 99; cycle += 1) {
      setTimeout(() => {
        dispatch({
          type: types.PROGRESSBAR_UPDATE,
          payload: 1,
        });
      }, totalTime * cycle);
    }
    API.get(`/api-external/api-players?${query}`).then((response) => {
      if (
        response.data &&
        response.data.data &&
        Array.isArray(response.data.data)
      ) {
        const payload = {
          ...response.data,
          time: Date.now(),
        };
        dispatch({
          type: types.PROGRESSBAR_UPDATE,
          payload: 1,
        });
        dispatch({
          type: types.GET_REAL_PLAYERS_SUCCESS,
          payload,
        });
      } else if (response.data && Array.isArray(response.data)) {
        const payload = {
          ...response,
          time: Date.now(),
        };
        dispatch({
          type: types.PROGRESSBAR_UPDATE,
          payload: 1,
        });
        dispatch({
          type: types.GET_REAL_PLAYERS_SUCCESS,
          payload,
        });
      }
      if (onSuccess) {
        onSuccess(response);
        setTimeout(() => {
          dispatch({
            type: types.PROGRESSBAR_SHOW,
            payload: false,
          });
        }, 1500);
      }
    });
  };

export const getDraftResults = (leagueId) => (dispatch) => {
  API.get(`/leagues/${leagueId}/ws-draft-rounds`).then((response) => {
    if (response.data && Array.isArray(response.data)) {
      dispatch({
        type: types.SET_DRAFT_RESULTS,
        payload: response.data,
      });
    }
  });
};

export const getRealPlayersForLeaguePlayers =
  (query = '', onSuccess) =>
  async (dispatch) => {
    API.get(`/api-external/api-players?${query}`).then((response) => {
      if (
        response.data &&
        response.data.data &&
        Array.isArray(response.data.data)
      ) {
        const payload = {
          ...response.data,
          time: Date.now(),
        };
        dispatch({
          type: types.GET_REAL_PLAYERS_SUCCESS_FOR_PLAYER,
          payload,
        });
      } else if (response.data && Array.isArray(response.data)) {
        const payload = {
          ...response,
          time: Date.now(),
        };
        dispatch({
          type: types.GET_REAL_PLAYERS_SUCCESS_FOR_PLAYER,
          payload,
        });
      }
      if (onSuccess) {
        onSuccess(response);
      }
    });
  };

export const getLeagueWaivers =
  (league, query = '', onSuccess) =>
  (dispatch) => {
    API.get(`/leagues/${league}/league-waivers${query}`).then((response) => {
      if (response.data && Object.keys(response.data).length !== 0) {
        const payload = {};
        const waiverRequests = {};
        Object.values(response.data).forEach((item) => {
          payload[item.api_player_id] = item.id;
          if (Array.isArray(item.league_waiver_requests)) {
            item.league_waiver_requests.forEach((req) => {
              if (waiverRequests[item.api_player_id])
                waiverRequests[item.api_player_id][req.league_team_id] = true;
              else
                waiverRequests[item.api_player_id] = {
                  [req.league_team_id]: true,
                };
            });
          }
        });
        dispatch({
          type: types.GET_LEAGUE_WAIVERS_SUCCESS,
          payload,
          waiverRequests,
        });
      }
      if (onSuccess) {
        onSuccess(response);
      }
    });
  };

export const getDesiredPlayers =
  (leagueId, teamId, onSuccess) => (dispatch) => {
    API.get(
      `/leagues/${leagueId}/league-teams/${teamId}/league-team-favorites`
    ).then((response) => {
      if (response.data && Array.isArray(response.data)) {
        const obj = {};
        response.data.forEach((player) => {
          obj[player.api_player_id] = player.id;
        });
        dispatch({
          type: types.GET_DESIRED_PLAYERS_SUCCESS,
          payload: { arr: response.data, obj },
        });
      }
      if (onSuccess) {
        onSuccess(response);
      }
    });
  };

export const addDesiredPlayer = (leagueId, teamId, data, onSuccess) => () => {
  API.post(
    `/leagues/${leagueId}/league-teams/${teamId}/league-team-favorites`,
    data
  ).then((response) => {
    if (onSuccess) {
      onSuccess(response);
    }
  });
};

export const deleteDesiredPlayer =
  (leagueId, teamId, playerId, onSuccess) => () => {
    API.delete(
      `/leagues/${leagueId}/league-teams/${teamId}/league-team-favorites/${playerId}`
    ).then((response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    });
  };

export const goUpDesiredPlayer =
  (leagueId, teamId, playerId, data, onSuccess) => () => {
    API.put(
      `/leagues/${leagueId}/league-teams/${teamId}/league-team-favorites/${playerId}`,
      data
    ).then((response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    });
  };
