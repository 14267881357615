import reducers from './reducers';

export {
  getRealTeams,
  getFantasyTeams,
  getStages,
  getStagesWeeks,
  getRealPlayers,
  getRealPlayersForLeaguePlayers,
  setDraftPlayersSelections,
  deleteLeaguePlayers,
  addDesiredPlayer,
  deleteDesiredPlayer,
  getDesiredPlayers,
  goUpDesiredPlayer,
  getLeagueWaivers,
  getDraftResults,
  getLeagueTeams,
} from './operations';

export default reducers;
