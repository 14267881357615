import * as types from './types';

const initialState = {
  user: {},
  notifications: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.LOGIN_SUCCESS:
      return {
        ...payload,
      };
    case types.LOGOUT:
      return initialState;
    case types.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: payload,
      };
    case types.SET_USER:
      return {
        ...state,
        user: {
          ...(state.user || {}),
          ...(payload || {}),
        },
      };
    default:
      return state;
  }
};
