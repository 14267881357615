/* eslint-disable no-param-reassign */
export const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export function getJsonFromUrl(location) {
  const url = location.search;
  const query = url.substr(1);
  const result = {};
  query.split('&').forEach((part) => {
    const item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

export function validateEmail(email) {
  return /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(email);
}

export function isValidUrl(url, obligatory = 1, ftp) {
  // Si no se especifica el paramatro "obligatory", interpretamos
  // que no es obligatorio
  if (obligatory === undefined) obligatory = 0;
  // Si no se especifica el parametro "ftp", interpretamos que la
  // direccion no puede ser una direccion a un servidor ftp
  if (ftp === undefined) ftp = 0;

  if (url === '' && obligatory === 0) return true;
  let pattern;
  if (ftp) pattern = /^(http|https|ftp):\/\/[a-z0-9.-]+.[a-z]{2,4}/gi;
  else pattern = /^(http|https):\/\/[a-z0-9.-]+.[a-z]{2,4}/gi;

  if (url.match(pattern)) return true;
  return false;
}

export const formatCurrency = (n, curr = null, numberOfDecimals = 0) => {
  n = parseInt(n, 10);
  if (Number.isNaN(Number(n))) return '';
  const currency = curr ? `${curr} ` : '';
  if (numberOfDecimals)
    return (
      currency +
      n.toFixed(numberOfDecimals).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    );
  return `${currency + n}.`
    .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    .replace('.', '');
};

export function justNumbers(e) {
  if (typeof e === 'string') {
    return /\d/.test(e);
  }
  const keynum = e.keyCode || e.which;
  const result = /\d/.test(String.fromCharCode(keynum));
  if (!result) {
    e.preventDefault();
  }
  return result;
}

export function phoneNumber(e) {
  const result = e.target.value.match(/^\+?\d*$/);
  return result;
}

export function justLetters(e) {
  if (typeof e === 'string') return /^[a-z A-Z]*$/.test(e);
  const key = e.keyCode || e.which;
  const result = /^[a-z A-Z]*$/.test(String.fromCharCode(key));
  if (!result) {
    e.preventDefault();
  }

  return /^[a-z A-Z]*$/.test(String.fromCharCode(key));
}
export function notSpaces(e) {
  const key = e.keyCode || e.which;
  if (key === 32) {
    return false;
  }
  return true;
}

export function notItitWithSpaces(e) {
  const key = e.keyCode || e.which;
  if (key === 32 && e.target.value.length === 0) {
    return false;
  }
  return true;
}

export function justLettersOrNumbers(e) {
  if (typeof e === 'string') return /\d/.test(e) || /^[a-z A-Záéóíú]*$/.test(e);

  const key = e.keyCode || e.which;
  if (
    /\d/.test(String.fromCharCode(key)) ||
    /^[a-z A-Z]*$/.test(String.fromCharCode(key))
  ) {
    return true;
  }
  e.preventDefault();
  return false;
}

/**
 * Helper function to interleave two arrays
 * @param {Array} array1
 * @param {Array} array2
 * @returns {Array}
 * @example
 * interleaveArrays([1, 2, 3], ['a', 'b', 'c']); // [1, 'a', 2, 'b', 3, 'c']
 * interleaveArrays([1, 2, 3], ['a', 'b']); // [1, 'a', 2, 'b', 3]
 * interleaveArrays([1, 2, 3], []); // [1, 2, 3]
 * interleaveArrays([], [1, 2, 3]); // [1, 2, 3]
 * */
export function interleaveArrays(array1, array2) {
  const result = [];
  let index1 = 0;
  let index2 = 0;

  while (index1 < array1.length && index2 < array2.length) {
    result.push(array1[index1]);
    result.push(array2[index2]);
    index1 += 1;
    index2 += 1;
  }

  // Add any remaining elements from array1, if there are any
  while (index1 < array1.length) {
    result.push(array1[index1]);
    index1 += 1;
  }

  // Add any remaining elements from array2, if there are any
  while (index2 < array2.length) {
    result.push(array2[index2]);
    index2 += 1;
  }

  return result;
}

/**
 * Helper function to sort players by position
 * @param {Object} player1
 * @param {Object} player2
 * @returns {Number}
 *
 * Sort order:
 * Goalkeeper > Defender > Midfielder > Attacker
 *
 */
export const sortPlayersByPosition = (player1, player2) => {
  if (player1.position === 'Goalkeeper') {
    return -1;
  }
  if (player2.position === 'Goalkeeper') {
    return 1;
  }
  if (player1.position === 'Defender') {
    return -1;
  }
  if (player2.position === 'Defender') {
    return 1;
  }
  if (player1.position === 'Midfielder') {
    return -1;
  }
  if (player2.position === 'Midfielder') {
    return 1;
  }
  if (player1.position === 'Attacker') {
    return -1;
  }
  if (player2.position === 'Attacker') {
    return 1;
  }
  return 0;
};
