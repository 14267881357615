import LocalizedStrings from 'react-localization';
import en from './en';
import es from './es';

const langStrings = new LocalizedStrings({
  es,
  en,
});

export default langStrings;
