import * as types from './types';

const initialState = {
  leagueThreads: [],
};

export default function draftStore(state = initialState, action) {
  switch (action.type) {
    case types.SET_CURRENT_THREAD:
      return {
        ...state,
        currentThread: action.payload,
      };
    case types.GET_LEAGUE_THREADS_SUCCESS:
      return {
        ...state,
        leagueThreads: action.payload,
      };
    default:
      return state;
  }
}
