import { REHYDRATE } from 'redux-persist';
import moment from 'moment';
import * as types from './types';
import en from '../../i18n/en';
import es from '../../i18n/es';
import langStrings from '../../i18n';

const initialState = {
  lang: langStrings.getLanguage() === 'en' ? en : es,
  langCode: langStrings.getLanguage(),
};

const ES_LANGUAGE = 'es';
export default (state = initialState, { type, lang, payload }) => {
  let incoming;
  switch (type) {
    case types.SET_EN:
      return {
        lang,
        langCode: 'en',
      };
    case types.SET_ES:
      return {
        lang,
        langCode: ES_LANGUAGE,
      };
    case REHYDRATE: {
      const isUserLogin =
        payload &&
        payload.accessStore &&
        payload.accessStore.user &&
        payload.accessStore.user.id &&
        payload.accessStore.user.id !== undefined;
      incoming = payload && payload.i18nStore;
      if (isUserLogin && incoming) {
        if (incoming.langCode) {
          langStrings.setLanguage(incoming.langCode);
          moment.locale(incoming.langCode);
        } else {
          langStrings.setLanguage(ES_LANGUAGE);
          moment.locale(ES_LANGUAGE);
        }
        return {
          ...incoming,
        };
      }
      // If not login put es by default.
      langStrings.setLanguage(ES_LANGUAGE);
      moment.locale(ES_LANGUAGE);
      initialState.langCode = ES_LANGUAGE;
      return initialState;
    }
    default:
      return state;
  }
};
