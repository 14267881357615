import * as types from './types';
import API from '../../api';

export const getParameters = () => (dispatch) => {
  API.get(`/parameters`).then((response) => {
    if (response.data && Array.isArray(response.data)) {
      const payload = {};
      response.data.forEach((item) => {
        if (item.key === 'tournament_current_week')
          payload.currentWeek = item.value;
        if (item.key === 'tournament_current_name')
          payload.tournamentStage = item.value;
      });
      dispatch({
        type: types.GET_PARAMETERS,
        payload,
      });
    }
  });
};

export const positionsTable = (onSuccess) => (dispatch) => {
  API.get(`/home/api-teams`).then((response) => {
    if (Array.isArray(response.data)) {
      dispatch({
        type: types.GET_POSITIONS_TABLE,
        payload: response.data,
      });
    }
    if (onSuccess) onSuccess(response);
  });
};

export const playersSaves = (onSuccess) => (dispatch) => {
  API.get(`/home/api-players?page=1&per_page=5&sort=-saves`).then(
    (response) => {
      if (response.data && Array.isArray(response.data)) {
        dispatch({
          type: types.GET_PLAYERS_RANKING,
          payload: response.data,
          id: 'Saves',
        });
      }
      if (onSuccess) onSuccess(response);
    }
  );
};

export const playersScore = (onSuccess) => (dispatch) => {
  API.get(`/home/api-players?page=1&per_page=5&sort=-goals`).then(
    (response) => {
      if (response.data && Array.isArray(response.data)) {
        dispatch({
          type: types.GET_PLAYERS_RANKING,
          payload: response.data,
          id: 'Score',
        });
      }
      if (onSuccess) onSuccess(response);
    }
  );
};

export const playersAssists = (onSuccess) => (dispatch) => {
  API.get(`/home/api-players?page=1&per_page=5&sort=-assist`).then(
    (response) => {
      if (response.data && Array.isArray(response.data)) {
        dispatch({
          type: types.GET_PLAYERS_RANKING,
          payload: response.data,
          id: 'Assists',
        });
      }
      if (onSuccess) onSuccess(response);
    }
  );
};

export const playersDefense = (onSuccess) => (dispatch) => {
  API.get(
    `/home/api-players?page=1&per_page=5&sort=-statistics_total_defense`
  ).then((response) => {
    if (response.data && Array.isArray(response.data)) {
      dispatch({
        type: types.GET_PLAYERS_RANKING,
        payload: response.data,
        id: 'Defense',
      });
    }
    if (onSuccess) onSuccess(response);
  });
};

// Cambiar a que apunte a equipo ideal del año
// Endpoint de equipo semanal: /home/api-ideal-team
export const getIdealTeam = (onSuccess) => (dispatch) => {
  API.get(`/home/api-ideal-team`).then((response) => {
    if (response.data) {
      dispatch({
        type: types.GET_IDEAL_TEAM,
        payload: response.data,
      });
    }
    if (onSuccess) onSuccess(response);
  });
};

export const getIdealTeamOfYear = (onSuccess) => (dispatch) => {
  API.get(`/home/api-ideal-team-of-year`).then((response) => {
    if (response.data) {
      dispatch({
        type: types.GET_IDEAL_TEAM_OF_YEAR,
        payload: response.data,
      });
    }
    if (onSuccess) onSuccess(response);
  });
};

export const getInShapePlayers = (onSuccess) => (dispatch) => {
  API.get(`/home/api-in-shape-players`).then((response) => {
    if (response.data && Array.isArray(response.data)) {
      dispatch({
        type: types.GET_IN_SHAPE_PLAYERS,
        payload: response.data,
      });
    }
    if (onSuccess) onSuccess(response);
  });
};

export const getPlayersRecommended = (onSuccess) => (dispatch) => {
  API.get(`/home/api-players-recommended`).then((response) => {
    if (response.data && Array.isArray(response.data)) {
      dispatch({
        type: types.GET_PLAYERS_RECOMMENDED,
        payload: response.data,
      });
    }
    if (onSuccess) onSuccess(response);
  });
};

export const playersCleanSheets = (onSuccess) => (dispatch) => {
  API.get(
    `/home/api-players?page=1&per_page=5&sort=-statistics_total_clean_sheets`
  ).then((response) => {
    if (response.data && Array.isArray(response.data)) {
      dispatch({
        type: types.GET_PLAYERS_RANKING,
        payload: response.data,
        id: 'CleanSheets',
      });
    }
    if (onSuccess) onSuccess(response);
  });
};

export const getArticlesAndContent = (onSuccess) => (dispatch) => {
  API.get(
    `/home/articles?sort=position&page=1&per_page=8&language=es&section=post_primary`
  ).then((response) => {
    if (response.data && Array.isArray(response.data)) {
      console.log(response.data, 'Articles');
      dispatch({
        type: types.GET_ARTICLES_AND_CONTENT,
        payload: response.data,
      });
    }
    if (onSuccess) onSuccess(response);
  });
};

export const getArticles =
  (onSuccess, admin, page = 1, perPage = 7, language = 'es') =>
  (dispatch) => {
    const url = `/home/articles?sort=position&page=${String(page)}&per_page=${
      admin ? '20' : String(perPage)
    }&language=${language}&section=post_primary`;
    API.get(url).then((response) => {
      if (response.data && Array.isArray(response.data.data)) {
        dispatch({ type: types.GET_NOTICES_SUCCESS, payload: response.data });
      }
      if (onSuccess) onSuccess(response);
    });
  };

export const getArticlesPagination = async ({
  page = 1,
  perPage = 10,
  language = 'es',
}) => {
  let posts = [];
  let total = 0;
  const url = `/articles?sort=-section&page=${String(page)}&per_page=${String(
    perPage
  )}&language=${language}`;
  const response = await API.get(url);
  if (
    response &&
    response.data &&
    response.data.data &&
    Array.isArray(response.data.data)
  ) {
    posts = [...response.data.data];
    total += response.data.total;
  }
  return [posts, total];
};

export const getArticleDetails = (id, onSuccess) => (dispatch) => {
  API.get(`/articles/${id}`).then((response) => {
    if (response.data && response.data.id) {
      dispatch({ type: types.SET_CURRENT_NOTICE, payload: response.data });
    }
    if (onSuccess) onSuccess(response);
  });
};

export const getArticlesMainSlider =
  (onSuccess, admin, page = 1, perPage = 7, language = 'es') =>
  (dispatch) => {
    const url = `/articles?sort=position&page=${String(page)}&per_page=${
      admin ? '20' : String(perPage)
    }&language=${language}&section=slide_primary`;
    API.get(url).then((response) => {
      if (
        response.data &&
        response.data.data &&
        Array.isArray(response.data.data)
      ) {
        dispatch({
          type: types.GET_SLIDER_SUCCESS,
          payload: response.data.data,
        });
      }
      onSuccess(response);
    });
  };

export const createArticle = (data, onSuccess) => () => {
  const url = `/articles`;
  API.post(url, data).then((response) => {
    onSuccess(response);
  });
};

export const updateArticleImage = (articleId, data, onSuccess) => () => {
  const form = new FormData();
  form.append('image', data);
  form.append('_method', 'PUT');
  API.post(`/articles/${articleId}`, form).then((response) => {
    if (onSuccess) {
      onSuccess(response);
    }
  });
};

export const deleteNotice = (data, onSuccess) => () => {
  const url = `/articles/${data.id}`;
  API.delete(url, data).then((response) => {
    onSuccess(response);
  });
};

export const editNotice = (data, onSuccess) => () => {
  const url = `/articles/${data.id}`;
  API.put(url, data).then((response) => {
    onSuccess(response);
  });
};

export const setCurrentNotice = (data) => ({
  type: types.SET_CURRENT_NOTICE,
  payload: data,
});

export const matchExternalDetail = (id, onSuccess) => () => {
  API.get(`/home/api-matches/${id}`).then((response) => {
    onSuccess(response);
  });
};

export const gamesResults = (currentWeek) => (dispatch) => {
  API.get(`/home/api-matches?week=${currentWeek}`).then((response) => {
    if (Array.isArray(response.data)) {
      const matches = [];
      response.data.forEach((match) => {
        if (match.locale_api_team && match.visitor_api_team) {
          const {
            locale_score: localeScore,
            visitor_score: visitorScore,
            status,
            id,
            date,
            locale_api_team: { name, image, short_name },
            visitor_api_team: {
              name: nameVisitor,
              image: imageVisitor,
              short_name: shortName,
            },
            minutes,
            week,
          } = match;
          matches.push({
            week,
            locale_score: localeScore,
            visitor_score: visitorScore,
            status,
            id,
            date,
            minutes,
            locale_api_team: { name, image, short_name },
            visitor_api_team: {
              name: nameVisitor,
              image: imageVisitor,
              short_name: shortName,
            },
          });
        }
      });
      dispatch({
        type: types.GET_EXTERNAL_MATCHES,
        payload: matches,
      });
    }
  });
};
