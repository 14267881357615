import * as types from './types';
import { GET_COUNTRIES } from '../league/types';

const initialState = {
  externalMatches: [],
  positionsTable: [],
  rankings: {},
  currentNotice: {},
  notices: {
    data: [],
  },
  idealTeam: {},
  inShapePlayers: {},
  playersRecommended: {},
  slides: [],
  tournamentStage: {},
  articlesHome: [],
};

export default (state = initialState, { type, payload, id }) => {
  switch (type) {
    case types.GET_NOTICES_SUCCESS:
      return {
        ...state,
        notices: payload,
      };
    case types.GET_SLIDER_SUCCESS:
      return {
        ...(state || {}),
        slides: payload || [],
      };
    case types.SET_CURRENT_NOTICE:
      return {
        ...state,
        currentNotice: payload,
      };
    case types.GET_PLAYERS_RANKING:
      return {
        ...state,
        rankings: state.rankings
          ? { ...state.rankings, [id]: payload }
          : { [id]: payload },
      };
    case types.GET_IDEAL_TEAM:
      return {
        ...state,
        idealTeam: payload,
      };
    case types.GET_IN_SHAPE_PLAYERS:
      return {
        ...state,
        inShapePlayers: payload,
      };
    case types.GET_PLAYERS_RECOMMENDED:
      return {
        ...state,
        playersRecommended: payload,
      };
    case types.GET_ARTICLES_AND_CONTENT:
      return {
        ...state,
        articlesAndContent: payload,
      };
    case types.GET_POSITIONS_TABLE:
      return {
        ...state,
        positionsTable: payload,
      };
    case types.GET_EXTERNAL_MATCHES:
      return {
        ...state,
        externalMatches: payload,
      };
    case types.GET_PARAMETERS:
      return {
        ...state,
        ...payload,
      };
    case GET_COUNTRIES:
      return {
        ...state,
        countries: payload,
      };
    default:
      return state;
  }
};
