import moment from 'moment';
import { toast } from 'react-toastify';
import API from '../../api';
import langStrings from '../../i18n';
import * as types from './types';
import { SET_EN, SET_ES } from '../i18n/types';

export const logout = () => {
  localStorage.removeItem('access_token');
  return {
    type: types.LOGOUT,
  };
};

const login = (data, success) => (dispatch) => {
  API.post(`/login`, data).then((response) => {
    if (response.success) {
      localStorage.setItem('access_token', response.data.token);
      API.get(`/authenticate`, data).then((response) => {
        dispatch({
          type: types.LOGIN_SUCCESS,
          payload: { user: response.data },
        });
        if (response.data && response.data.language) {
          langStrings.setLanguage(response.data.language);
          moment.locale(response.data.language);
          if (response.data.language === 'en') {
            dispatch({ type: SET_EN, lang: response.data.language });
          } else if (response.data.language === 'es') {
            dispatch({ type: SET_ES, lang: response.data.language });
          }
        }
        success(response);
      });
    } else {
      success(response);
    }
  });
};

export const authenticate = (token, onSuccess) => (dispatch) => {
  localStorage.setItem('access_token', token);
  API.get(`/authenticate`).then((response) => {
    dispatch({
      type: types.LOGIN_SUCCESS,
      payload: { user: response.data },
    });
    if (onSuccess) {
      onSuccess(response);
    }
  });
};

const register = (data, success) => (dispatch) => {
  API.post(`/users`, data).then((response) => {
    console.log(response);
    if (response.success) {
      localStorage.setItem('access_token', response.data.token);
      localStorage.setItem('first_login', true);
      dispatch({
        type: types.LOGIN_SUCCESS,
        payload: { user: response.data },
      });
      toast.success(langStrings.loginSuccessfully, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'z-index-toast',
      });
      success(response);

      // DL event for successful registration
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'registrationComplete',
        user_email: response.data.email,
        user_first_name: response.data.firstnames,
        user_last_name: response.data.lastnames,
        user_gender: response.data.gender,
        user_id: response.data.id_user_joga,
        user_country: response.data.country,
        user_favorite_team: response.data.favorite_team,
      });
    } else {
      success(response);
    }
  });
};

const recoveryPassword = (data, success) => () => {
  API.post(`/auth/forgot-password`, data).then((response) => {
    console.log(response);
    if (response.success) {
      success(response);
    } else {
      success(response);
    }
  });
};

const confirmEmail = (data, success) => () => {
  API.get(`/auth/confirm-user/${data}`).then((response) => {
    console.log(response);
    if (response.success) {
      success(response);
    } else {
      success(response);
    }
  });
};

const recoveryPasswordConfirm = (data, success) => () => {
  API.post(`/auth/reset-password`, data).then((response) => {
    console.log(response);
    if (response.success) {
      success(response);
    } else {
      success(response);
    }
  });
};

export const changePasswordP = (userId, data, success) => () => {
  API.put(`/users/${userId}/password-change`, data).then((response) => {
    if (response.success) {
      success(response);
    } else {
      success(response);
    }
  });
};

export const editProfiles = (userId, data, success) => (dispatch) => {
  API.put(`/users/${userId}`, data).then((response) => {
    if (response.success) {
      success(response);
      dispatch({ type: types.SET_USER, payload: response?.data ?? {} });
    } else {
      success(response);
    }
  });
};

export const getNotifications = (userId, query) => (dispatch) => {
  API.get(`/users/${userId}/notifications${query}`).then((response) => {
    console.log(response);
    if (Array.isArray(response.data)) {
      dispatch({
        type: types.GET_NOTIFICATIONS_SUCCESS,
        payload: response.data,
      });
    }
  });
};

export {
  login,
  register,
  recoveryPassword,
  confirmEmail,
  recoveryPasswordConfirm,
};
