import API from '../../api';
import * as types from './types';

export const setCurrentThread = (data) => ({
  type: types.SET_CURRENT_THREAD,
  payload: data,
});

export const createThread = (leagueId, data, onSuccess) => () => {
  API.post(`/leagues/${leagueId}/league-threads`, data).then((response) => {
    // console.log(response)
    // if (Array.isArray(response.data)) {
    //   dispatch({ type: types.GET_MY_TEAMS_SUCCESS, payload: response.data });
    // }
    if (onSuccess) {
      onSuccess(response);
    }
  });
};

export const getThreads = (leagueId, onSuccess) => (dispatch) => {
  API.get(`/leagues/${leagueId}/league-threads`).then((response) => {
    // console.log(response)
    if (Array.isArray(response.data)) {
      dispatch({
        type: types.GET_LEAGUE_THREADS_SUCCESS,
        payload: response.data,
      });
    }
    if (onSuccess) {
      onSuccess(response);
    }
  });
};

export const publishMessage = (threadId, data, onSuccess) => () => {
  API.post(`/league-threads/${threadId}/league-thread-posts`, data).then(
    (response) => {
      // console.log(response)
      // if (Array.isArray(response.data)) {
      //   dispatch({ type: types.GET_MY_TEAMS_SUCCESS, payload: response.data });
      // }
      if (onSuccess) {
        onSuccess(response);
      }
    }
  );
};

export const getThreadMessages =
  (leagueId, threadId, onSuccess) => (dispatch) => {
    API.get(`/leagues/${leagueId}/league-threads/${threadId}`).then(
      (response) => {
        // console.log(response)
        if (response.data && response.data.id) {
          dispatch({ type: types.SET_CURRENT_THREAD, payload: response.data });
        }
        if (onSuccess) {
          onSuccess(response);
        }
      }
    );
  };
