import reducers from './reducers';

export default reducers;

export {
  login,
  authenticate,
  logout,
  register,
  recoveryPassword,
  confirmEmail,
  recoveryPasswordConfirm,
  changePasswordP,
  getNotifications,
  editProfiles,
} from './operations';
