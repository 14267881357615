/* eslint-disable no-param-reassign */
import axios from 'axios';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import langStrings from '../i18n';
import { store } from '../index';
import { LOGOUT } from '../ducks/access/types';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

const API = axios.create({
  baseURL: BASE_URL,
  timeout: 30000000,
});

API.interceptors.request.use(
  async (config) => {
    const token = await localStorage.getItem('access_token');
    const lang = langStrings.getLanguage();
    if (token && config.url.indexOf('/login') === -1 && token !== 'undefined') {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (lang) {
      config.headers.language = lang;
    }

    const userTimeZone = moment.tz.guess();
    if (userTimeZone) {
      config.headers.timezone = userTimeZone;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
API.interceptors.response.use(
  (response) => {
    const data = {
      success: true,
      data: response.data,
      message: 'Petición exitosa!',
      code: response.status,
    };

    return data;
  },
  (error) => {
    const data = {
      success: false,
      data: error.response ? error.response.data : null,
      message: 'Petición fallida!',
      code: error.response ? error.response.status : -1,
    };
    const status = error.response ? error.response.status : -1;
    switch (status) {
      case 500:
        data.success = false;
        data.message = langStrings.InternalErrorMessage;
        break;
      case -1:
        data.success = false;
        data.message = langStrings.connectionErrorMessage;
        break;
      case 400:
        data.success = false;
        data.message = langStrings.badRequestMessage;
        break;
      case 401:
        store.dispatch({ type: LOGOUT });
        localStorage.removeItem('access_token');
        toast.info(langStrings.SessionExpiredMessage, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'z-index-toast',
        });
        data.success = false;
        data.message =
          'Disculpe, no está autorizado para realizar esta acción.';
        break;
      case 404:
        data.success = false;
        data.message = langStrings.notFoundError;
        break;
      default:
        break;
    }
    return data;
  }
);

export { BASE_URL, SOCKET_URL };
export default API;
