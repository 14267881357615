import moment from 'moment';
import * as types from './types';
import en from '../../i18n/en';
import es from '../../i18n/es';
import langStrings from '../../i18n';
import API from '../../api';

let log_in_status = false;

const setEn = (userId) => (dispatch) => {
  langStrings.setLanguage('en');
  moment.locale('en');
  dispatch({ type: types.SET_EN, lang: en });
  if (userId) {
    API.put(`/users/${userId}/language`, { language: 'en' });
    log_in_status = true;
  }

  // DL event for languange change to english
  window.dataLayer.push({
    event: 'language_change',
    language: 'en',
    log_in_status,
  });
};

const setEs = (userId) => (dispatch) => {
  langStrings.setLanguage('es');
  moment.locale('es');
  dispatch({ type: types.SET_ES, lang: es });
  if (userId) {
    API.put(`/users/${userId}/language`, { language: 'es' });
    log_in_status = true;
  }

  // DL event for languange change to spanish
  window.dataLayer.push({
    event: 'language_change',
    language: 'es',
    log_in_status,
  });
};
export { setEn, setEs };
