import * as types from './types';
import { SET_CURRENT_LEAGUE } from '../league/types';

const initialState = {
  realTeams: [],
  fantasyTeams: [],
  stages: [],
  currentStage: {},
  stagesWeeks: [],
  realPlayers: {
    data: [],
  },
  draftPlayers: {
    leagueId: null,
    players: [],
  },
  progressBar: 0,
  progressBarShow: false,
  userPlayers: {},
  startersPlayers: {},
  draftPlayersIds: {},
  userPlayersIds: {},
  desiredPlayers: {},
  desiredPlayersArr: [],
  desiredPlayersIds: {},
  draftResults: [],
};

export default function draftStore(state = initialState, action) {
  let draftPlayers = {};
  const userPlayers = state.userPlayers ? state.userPlayers : {};
  const startersPlayers = state.startersPlayers ? state.startersPlayers : {};
  const desiredPlayers = state.desiredPlayers ? state.desiredPlayers : {};
  let userPlayersIds = {};
  let draftPlayersIds = {};
  const newUserPlayers = {};
  const newStartersPlayers = {};
  let draftResults = [];
  let newDesiredPlayers;

  switch (action.type) {
    case types.SET_DRAFT_RESULTS:
      return {
        ...state,
        draftResults: action.payload,
      };
    case types.GET_LEAGUE_WAIVERS_SUCCESS:
      return {
        ...state,
        leagueWaivers: action.payload,
        waiverRequests: action.waiverRequests,
      };
    case types.DELETE_DRAFT_PLAYERS:
      return {
        ...state,
        draftPlayers: {
          leagueId: null,
          players: [],
        },
        userPlayers: {},
        draftPlayersIds: {},
        startersPlayers: {},
        userPlayersIds: {},
      };
    case SET_CURRENT_LEAGUE:
      draftPlayers.players = [];
      if (action.payload && Array.isArray(action.payload.league_teams)) {
        action.payload.league_teams.forEach((team) => {
          if (Array.isArray(team.league_team_players)) {
            team.league_team_players.forEach((player) => {
              if (team.user && team.user.username === action.username) {
                userPlayersIds[player.api_player_id] = true;
                if (Array.isArray(newUserPlayers[`${player.position}s`])) {
                  newUserPlayers[`${player.position}s`].push(player);
                } else {
                  newUserPlayers[`${player.position}s`] = [player];
                }
                if (player.position_type === 'titular') {
                  if (
                    Array.isArray(newStartersPlayers[`${player.position}s`])
                  ) {
                    newStartersPlayers[`${player.position}s`].push(player);
                  } else {
                    newStartersPlayers[`${player.position}s`] = [player];
                  }
                }
              }
              draftPlayersIds[player.api_player_id] = team.team_name;
              draftPlayers.players.push({
                team: { id: team.id, image: team.image, team: team.team_name },
                player,
              });
            });
          }
        });
      }

      return {
        ...state,
        draftPlayers,
        userPlayers: newUserPlayers,
        startersPlayers: newStartersPlayers,
        userPlayersIds,
        draftPlayersIds,
      };
    case types.SET_DRAFT_PLAYERS_SELECTION:
      draftPlayersIds = {
        ...state.draftPlayersIds,
        [action.payload.player.api_player_id]: action.payload.team.team,
      };
      if (
        action.payload.player &&
        action.username === action.payload.team.user
      ) {
        const { player } = action.payload;
        userPlayersIds = {
          ...state.userPlayersIds,
          [action.payload.player.api_player_id]: true,
        };
        if (state.userPlayers[`${player.position}s`]) {
          userPlayers[`${player.position}s`] = [
            ...state.userPlayers[`${player.position}s`],
            player,
          ];
        } else {
          userPlayers[`${player.position}s`] = [player];
        }
        if (player.position_type === 'titular') {
          if (state.startersPlayers[`${player.position}s`]) {
            startersPlayers[`${player.position}s`] = [
              ...state.startersPlayers[`${player.position}s`],
              player,
            ];
          } else {
            startersPlayers[`${player.position}s`] = [player];
          }
        }
      } else {
        userPlayersIds = { ...state.userPlayersIds };
      }
      if (
        action.payload.player &&
        state.draftPlayers.leagueId !== action.payload.player.league_id
      ) {
        draftPlayers = {
          leagueId: action.payload.league_id,
          players: [action.payload],
        };
      } else if (
        state.draftPlayers &&
        state.draftPlayers.players &&
        action.payload.player
      ) {
        draftPlayers = {
          leagueId: action.payload.player.league_id,
          players: [...state.draftPlayers.players, action.payload],
        };
      } else {
        draftPlayers = {
          players: [action.payload],
        };
      }
      if (state.draftResults && state.draftResults.length) {
        draftResults = [...state.draftResults, action.payload];
      } else {
        draftResults = [action.payload];
      }
      return {
        ...state,
        userPlayers,
        startersPlayers,
        draftPlayers,
        userPlayersIds,
        draftPlayersIds,
        draftResults,
      };
    case types.GET_REAL_PLAYERS_SUCCESS:
      return {
        ...state,
        realPlayers: action.payload,
      };
    case types.GET_REAL_PLAYERS_SUCCESS_FOR_PLAYER:
      return {
        ...state,
        realPlayersForPlayers: action.payload,
      };
    case types.GET_REAL_TEAMS_SUCCESS:
      return {
        ...state,
        realTeams: action.payload,
      };
    case types.GET_FANTASY_TEAMS_SUCCESS:
      return {
        ...state,
        fantasyTeams: action.payload,
      };
    case types.GET_STAGES_SUCCESS:
      return {
        ...state,
        stages: action.payload,
      };
    case types.GET_CURRENT_STAGES_SUCCESS:
      return {
        ...state,
        currentStage: action.payload,
      };
    case types.GET_STAGES_WEEKS_SUCCESS:
      return {
        ...state,
        stagesWeeks: action.payload,
      };
    case types.GET_DESIRED_PLAYERS_SUCCESS:
      return {
        ...state,
        desiredPlayers: action.payload.obj,
        desiredPlayersArr: action.payload.arr,
      };
    case types.PROGRESSBAR_UPDATE:
      return {
        ...state,
        progressBar: state.progressBar + action.payload,
      };
    case types.PROGRESSBAR_SHOW:
      return {
        ...state,
        progressBarShow: action.payload,
        progressBar: 0,
      };
    case types.ADD_DESIRED_PLAYER:
      return {
        ...state,
        desiredPlayers: {
          ...desiredPlayers,
          [action.payload.leagueId]: desiredPlayers[action.payload.leagueId]
            ? {
                ...state.desiredPlayers[action.payload.leagueId],
                [action.payload.id]: action.payload,
              }
            : { [action.payload.id]: action.payload },
        },
      };
    case types.DELETE_DESIRED_PLAYER:
      if (
        state.desiredPlayers &&
        state.desiredPlayers[action.payload.leagueId] &&
        state.desiredPlayers[action.payload.leagueId][action.payload.id]
      ) {
        newDesiredPlayers = JSON.parse(JSON.stringify(state.desiredPlayers));
        delete newDesiredPlayers[action.payload.leagueId][action.payload.id];
      }
      return {
        ...state,
        desiredPlayers: newDesiredPlayers || state.desiredPlayers,
      };
    default:
      return state;
  }
}
