import reducers from './reducers';

export {
  positionsTable,
  playersSaves,
  playersScore,
  playersAssists,
  playersDefense,
  getIdealTeam,
  getIdealTeamOfYear,
  getInShapePlayers,
  getPlayersRecommended,
  getArticlesAndContent,
  playersCleanSheets,
  getArticles,
  getArticlesMainSlider,
  getArticleDetails,
  createArticle,
  updateArticleImage,
  deleteNotice,
  editNotice,
  setCurrentNotice,
  matchExternalDetail,
  gamesResults,
  getParameters,
  getArticlesPagination,
} from './operations';

export default reducers;
